import React, { useEffect,useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Button,
  Row,
  Avatar,
  Typography,
  Layout,
  Checkbox,
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker
} from "antd";
import { useNavigate } from "react-router";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { UPLOADS_URL } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { Get } from "../../config/api/get";
import { SERVICES, COACH } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import {SUBJECTS, CONTENT_TYPE } from "../../config/constants/index";
import swal from "sweetalert";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
//icons
import { FaLongArrowAltLeft } from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb";
import { BsFiles } from "react-icons/bs";
import dayjs from "dayjs";

function Services() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [myServices,setMyServices] = useState([]);
  const [services, setServices] = useState([]);
  const [editMode,setEditMode] = useState(false);
  const [loading,setLoading] = useState(false);


  useEffect(() => {
    if(user){
      setMyServices(user?.service);
    }
    getAllServices();
  },[])

  
  const getAllServices = () => {
    try {
      Get(SERVICES.getAll, token).then((response) => {
        if (response?.status) {
          setServices(response?.data?.docs);
        } else {
          console.log("response", response);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  
  const updateServices = () =>{
    setLoading(true);

    if(myServices?.length == 0){
      swal("Error!", "Atleast one service is required", "error");
      setLoading(false);
      return;
    }

    Post(COACH.updateProfile,{service:myServices},token,null)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data)
          dispatch(
            addUser({ user: response.data.data, token: token })
          );

          swal("Success!", "Services Updated Successfully", "success");
          setLoading(false);
          setEditMode(false);
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {

        setLoading(false);
      });
  }


  const colors = ["#FFEBF6","#E7FAFF","#ECE7FF","#D7FDC3"]


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<>Services</>}
        </Typography.Title>
        <FaLongArrowAltLeft
          style={{
            fontSize: "30px",
            color: "#333d47",
            position: "absolute",
            left: 150,
            cursor: "pointer",
            marginTop: 20,
          }}
          onClick={() => navigate(-1)}
        />
      </Row>
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={16}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                minHeight:"400px"
              }}
            >

              {myServices?.length == 0 && <Row justify="center" style={{minHeight:"300px",alignItems:'center',flexDirection:"column"}}> <BsFiles style={{fontSize:"50px",color:'#7ec25d'}}/> <br/> <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 20,
                }}
              >
                No Services Found
              </Typography.Title></Row>}

              {myServices?.length > 0 && 
              <Row gutter={[20,20]} justify="flex-start"> 
              {myServices?.map((item,index) => {
                let title =services?.length > 0 && services.filter(items => items._id == item)[0].title || "";
                return(<Col xs={24} sm={12} md={6}>
                  <div style={{padding:20, backgroundColor: colors[Math.floor(index / 4) % colors?.length], display:"flex",justifyContent:"center",alignItems:"center",borderRadius:10}}>
                  <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  margin: 0,
                }}
              >
                {title}
             
              </Typography.Title>
          
                  </div>
                  </Col>);
              })}
              
               </Row>}

               {editMode && <Row style={{padding:"20px 0"}}>
                <Col xs={24} md={6} className="flex" style={{alignItems:"center"}}>  <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                Add Services
              </Typography.Title></Col>
                <Col xs={24} md={18}>
                <Select
                        size="large"
                        mode="multiple"
                        maxTagCount="responsive"
                        className="signupSelectBox"
                        placeholder="Select Services"
                        showSearch
                        value={myServices}
                        onChange={(e)=> setMyServices(e)}
                        style={{minWidth:"100%"}}
                      >   {services?.map((item, index) => {
                        return <option value={item._id}>{item.title}</option>;
                      })}
                      </Select>
                </Col>
         
               
                    
                </Row> }

                <br/>
        
        <Row justify="center">
      {!editMode ?   <Button
                    type="primary"
                    className="loginButton"
                    onClick={() => setEditMode(true)}
                  >
                    {"Edit Services"}
                  </Button> : <Button
                    type="primary"
                    className="loginButton"
                    onClick={() => updateServices()}
                  >
                    {loading ? "Loading..." : "Update Services"}
                  </Button>  }
        </Row>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Services;
