import React, { useEffect, useState } from "react";
import {
  Form,
  Slider,
  Input,
  Col,
  Row,
  Typography,
  Layout,
  Rate,
  Card,
  Tag,
  Skeleton,
  Table,
  Button,
  Progress,
  message,
  Avatar,
  Image,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import { Get } from "../../config/api/get";
import ReactPaginate from "react-paginate";
import {
  UPLOADS_URL,
  AUTH,
  LESSON,
  COACH,
  RATES,
  REVIEWS,
} from "../../config/constants/api";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { RiFileList2Fill } from "react-icons/ri";

//icons
import {
  FaArrowRight,
  FaArrowLeft,
  FaUserAlt,
  FaBox,
  FaUsers,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ImageUrl } from "../../helpers/Functions";
import CourseManagement from "./courseManagement";

function Courses() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const { Search } = Input;
  

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{
            fontSize: "30px",
            color: "#333d47",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {<>My Courses</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{
          backgroundColor: "white",
          justifyContent: "center",
          padding: "50px",
        }}
        gutter={20}
      >
        <Col xs={24} md={18}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <Row>
              <Card
                className="tutorDetailCard"
                style={{ width: "100%" }}
                bordered={false}
              >
                <CourseManagement />

                <br />
                <br />
              </Card>
            </Row>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Courses;
