import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Card,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  TimePicker,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOADS_URL, ASSESMENT, ADMIN } from "../../config/constants/api";
import { CONTENT_TYPE } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined } from "@ant-design/icons";
import { FaLongArrowAltLeft } from "react-icons/fa";

const { Option } = Select;
const { TextArea } = Input;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function AssesmentAdd() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assesment, setAssesment] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();

  const onFinish = (values) => {
    const formObject = new FormData();

    if (imageNew) {
      formObject.append("image", values.image.fileList[0].originFileObj);
    }

    formObject.append("coach", user._id);

    for (const key in values) {
      if (key !== "image") {
        const item = values[key];
        formObject.append(key, item);
      }
    }

    Post(
      ASSESMENT.addAssesment,
      formObject,
      token,
      null,
      CONTENT_TYPE.FORM_DATA
    )
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Assesment Added Successfully", "success");
          navigate(-1);

          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {});
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          className="blueBackground"
          style={{
            height: "45vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography.Title
            className="fontFamily1"
            style={{ fontSize: "30px", color: "black" }}
          >
            {<>Add Assesment</>}
          </Typography.Title>
          {/* <Button style={{width:"40px",height:"40px",background:"#7cc059", display:'flex',justifyContent:'center',alignItems:'center', color:"white", position:"absolute", left:150,cursor:"pointer", marginTop:20,padding:10, border:'none', borderRadius:'100px'}} onClick={()=> navigate(-1)}>
          
        <FaLongArrowAltLeft style={{fontSize:"30px" , color:"white"}} />
        </Button> */}
        </Row>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={22}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "100px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row style={{ padding: "20px" }}>
                  <Col xs={24} md={18}>
                    <Row>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginBottom: 20,
                        }}
                      >
                        File :
                      </Typography.Title>
                    </Row>
                    <Row>
                      <Form.Item name="image">
                        <Upload
                          name="image"
                          showUploadList={true}
                          style={{ position: "relative" }}
                          beforeUpload={(file) => {
                            setImageNew(URL.createObjectURL(file));
                            return false;
                          }}
                        >
                          {/* {imageNew ? <Image
preview={false}
                  src={imageNew
                    ? imageNew
                    : !assesment?.file
                    ? "/images/avatar.png"
                    : UPLOADS_URL + "/" + assesment?.file }
                  height={300}
                  width={500}
                  style={{ borderRadius: "10px", objectFit: "cover" }}
                /> : <div style={{height:300,width:500, padding:20, display:'flex', textAlign:"center", flexDirection:"column",justifyContent:"center", alignItems:"center", backgroundColor:"#fafafa", border:"2px dashed #dadada", borderRadius:'10px'}}> <p className="ant-upload-drag-icon">
                <InboxOutlined style={{fontSize:"30px"}} />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload only. Strictly prohibited from uploading company data or other
                banned files.
              </p></div>} */}

                          <div
                            style={{
                              height: 300,
                              width: 500,
                              padding: 20,
                              display: "flex",
                              textAlign: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#fafafa",
                              border: "2px dashed #dadada",
                              borderRadius: "10px",
                            }}
                          >
                            {" "}
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined style={{ fontSize: "30px" }} />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Support for a single upload only. Strictly
                              prohibited from uploading company data or other
                              banned files.
                            </p>
                          </div>
                        </Upload>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>

                <Row style={{ padding: "20px" }}>
                  <Col xs={24} md={18}>
                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Title :
                        </Typography.Title>

                        <Form.Item
                          name="title"
                          // initialValue={assesment?.title}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input assesment title",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Title"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Type :
                        </Typography.Title>

                        <Form.Item
                          name="type"
                          // initialValue={assesment?.price}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Price",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Category"
                            className="formSelectBox"
                            options={[
                              { value: "QUIZ", label: "Quiz" },
                              { value: "ASSIGNMENT", label: "Assignment" },
                            ]}
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Due Date :
                        </Typography.Title>

                        <Form.Item
                          name="dueDate"
                          // initialValue={dayjs(assesment?.assesmentDate)}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select assesment Date",
                            },
                            {
                              validator: (_, value) => {
                                if (value && value.isBefore(dayjs(), "day")) {
                                  return Promise.reject(
                                    new Error("Due Date cannot be in the past")
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Enter Assesment Date"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Due Time :
                        </Typography.Title>

                        <Form.Item
                          name="dueTime"
                          // initialValue={dayjs(assesment?.assesmentTime)}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select assesment Time",
                            },
                          ]}
                        >
                          <TimePicker
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Enter assesment Time"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Description:
                        </Typography.Title>

                        <Form.Item
                          name="description"
                          // initialValue={assesment?.description}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Assesment description",
                            },
                          ]}
                        >
                          <TextArea
                            rows={5}
                            size="large"
                            placeholder="Enter Description"
                            className="signupFormInput2"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <>
                        {" "}
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="loginButton"
                        >
                          Add Assesment
                        </Button>
                        &emsp;
                        <Button
                          className="fontFamily1"
                          style={{
                            marginTop: "0px",
                            padding: "10px 30px",
                            cursor: "pointer",
                            color: "black",
                            borderRadius: "5px",
                            height: "auto",
                            border: "1px solid #203657",
                            fontWeight: "bold",
                          }}
                          ghost
                          size="large"
                          onClick={(e) => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}
export default AssesmentAdd;
