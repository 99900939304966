import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Card,
  Layout,
  Avatar,
  Upload,
  Table,
  Select,
  Image,
  Modal,
  DatePicker,
  TimePicker,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import { UserOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FaCaretDown, FaFilter, FaArrowLeft } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { UPLOADS_URL, MEETING, ADMIN } from "../../config/constants/api";
import { CONTENT_TYPE } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { TbCameraPlus } from "react-icons/tb";
import { InboxOutlined } from "@ant-design/icons";
import { FaLongArrowAltLeft } from "react-icons/fa";

const { Option } = Select;
const { TextArea } = Input;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function MeetingAdd() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [meeting, setMeeting] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [imageNew, setImageNew] = useState();

  const handleStatus = async () => {
    try {
      const response = await Get(
        MEETING.toggleStatus + "/" + meeting._id,
        token,
        {}
      );
      const newUser = { ...meeting };

      newUser.isActive = !meeting.isActive;
      setModalOpen(false);
      setMeeting(newUser);
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFinish = (values) => {
    Post(MEETING.addMeeting, values, token, null)
      .then((response) => {
        if (response?.data?.status) {
          console.log(response?.data);

          swal("Success!", "Meeting Added Successfully", "success");
          navigate(-1);

          setEditMode(false);
          setImageNew();
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {});
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          className="blueBackground"
          style={{
            height: "45vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography.Title
            className="fontFamily1"
            style={{ fontSize: "30px", color: "black" }}
          >
            {<>Add Meeting</>}
          </Typography.Title>
          {/* <Button style={{width:"40px",height:"40px",background:"#7cc059", display:'flex',justifyContent:'center',alignItems:'center', color:"white", position:"absolute", left:150,cursor:"pointer", marginTop:20,padding:10, border:'none', borderRadius:'100px'}} onClick={()=> navigate(-1)}>
          
        <FaLongArrowAltLeft style={{fontSize:"30px" , color:"white"}} />
        </Button> */}
        </Row>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={22}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "100px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                // initialValues={{
                //   remember: true,
                // }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row style={{ padding: "20px" }}>
                  <Col xs={24} md={18}>
                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Title :
                        </Typography.Title>

                        <Form.Item
                          name="title"
                          // initialValue={meeting?.title}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input meeting title",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Title"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Fee :
                        </Typography.Title>

                        <Form.Item
                          name="charges"
                          // initialValue={meeting?.price}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Price",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%", padding: "5px" }}
                            size="large"
                            placeholder="Enter Meeting Fee"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Meeting Date :
                        </Typography.Title>

                        <Form.Item
                          name="meetingDate"
                          // initialValue={dayjs(meeting?.meetingDate)}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select meeting Date",
                            },
                            {
                              // Custom validator to ensure the meeting date is not in the past
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.resolve();
                                }
                                const today = dayjs().startOf("day");
                                if (value.isBefore(today)) {
                                  return Promise.reject(
                                    "Meeting date cannot be in the past."
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Enter Meeting Date"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Meeting Time :
                        </Typography.Title>

                        <Form.Item
                          name="meetingTime"
                          // initialValue={dayjs(meeting?.meetingTime)}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please select meeting Time",
                            },
                            {
                              // Custom validator to ensure a valid time is selected (optional)
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.resolve();
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <TimePicker
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Enter meeting Time"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Meeting Link :
                        </Typography.Title>

                        <Form.Item
                          name="meetingLink"
                          // initialValue={meeting?.title}
                          style={{ width: "100%" }}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Meeting Link"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Meeting Id :
                        </Typography.Title>

                        <Form.Item
                          name="meetingId"
                          // initialValue={meeting?.title}
                          style={{ width: "100%" }}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Meeting Id"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        sm={12}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Passcode :
                        </Typography.Title>

                        <Form.Item
                          name="passcode"
                          // initialValue={meeting?.title}
                          style={{ width: "100%" }}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Meeting Passcode"
                            className="signupFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={20}>
                      <Col
                        xs={24}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Title
                          className="fontFamily1"
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "black",
                            textAlign: "left",
                            marginBottom: 20,
                          }}
                        >
                          Description:
                        </Typography.Title>

                        <Form.Item
                          name="description"
                          // initialValue={meeting?.description}
                          style={{ width: "100%" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Meeting description",
                            },
                          ]}
                        >
                          <TextArea
                            rows={5}
                            size="large"
                            placeholder="Enter Description"
                            className="signupFormInput2"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: 30 }}>
                      <>
                        {" "}
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="loginButton"
                        >
                          Add Meeting
                        </Button>
                        &emsp;
                        <Button
                          className="fontFamily1"
                          style={{
                            marginTop: "0px",
                            padding: "10px 30px",
                            cursor: "pointer",
                            color: "black",
                            borderRadius: "5px",
                            height: "auto",
                            border: "1px solid #203657",
                            fontWeight: "bold",
                          }}
                          ghost
                          size="large"
                          onClick={(e) => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}
export default MeetingAdd;
