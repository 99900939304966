import React from "react";

import { Col, Row, Typography, Layout, Card, Image } from "antd";
import { ImageUrl } from "../../helpers/Functions";

function AboutUs() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Our Features</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={20}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row
                  gutter={[32, 32]}
                  align={"middle"}
                  justify={"space-between"}
                >
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                            Explore Education Through Exceptional Tutoring at
                            i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            Unlock a world of opportunities with personalized
                            tutoring at i12 Excellence Academy, designed to
                            foster growth and development. Our expert tutors
                            provide dedicated support, guiding students on a
                            journey of discovery and skill-building. With
                            tailored instruction, students gain valuable
                            knowledge and essential skills, setting the stage
                            for personal and professional advancement in a
                            dynamic and ever-changing world.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("futurepic1.jpg")}
                      preview={false}
                    //   style={{
                    //     width: "100%",
                    //     height: "500px",
                    //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("futurepic2.jpg")}
                      preview={false}
                    //   style={{
                    //     width: "100%",
                    //     height: "500px",
                    //   }}
                    />
                  </Col>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                            Affordable Tutoring for Accessible Education at i12
                            Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            Accessing affordable tutoring at i12 Excellence
                            Academy makes quality education accessible to
                            everyone, regardless of financial constraints. By
                            offering personalized support at reasonable rates,
                            we prioritize inclusivity and ensure that
                            individuals from diverse backgrounds can pursue
                            their academic goals. Our commitment is to provide
                            valuable educational opportunities that empower all
                            students to achieve their full potential.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                            Individual and Small Group Tutoring at i12
                            Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                              Personalized Learning for Maximum Impact
                            </strong>{" "}
                            <br />
                            <br />
                            Experience the benefits of individualized attention
                            and tailored instruction with our one-on-one and
                            small group tutoring sessions at i12 Excellence
                            Academy. Whether you prefer focused, personalized
                            support or collaborative learning with peers, our
                            expert tutors provide customized guidance to meet
                            your unique needs. Enhance understanding, build
                            confidence, and achieve academic success with a
                            learning experience designed for your success.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("futurepic3.jpg")}
                      preview={false}
                    //   style={{
                    //     width: "100%",
                    //     height: "500px",
                    //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default AboutUs;
