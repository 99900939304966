import React from "react";

import { Col, Row, Typography, Layout, Card, Image } from "antd";
import { ImageUrl } from "../../helpers/Functions";

function AboutUs() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<>Grade Tutoring</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={20}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row
                  gutter={[32, 32]}
                  align={"middle"}
                  justify={"space-between"}
                >
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                            Kinder-5th Grade ELAR Tutoring at i12 Excellence
                            Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                              Unlock Your Child's Potential in Reading and
                              Writing!
                            </strong>{" "}
                            <br />
                            <br />
                            Our tailored ELAR tutoring sessions for Kinder-5th
                            grade students at i12 Excellence Academy focus on
                            enhancing comprehension, vocabulary, grammar, and
                            writing skills. Whether your child needs help
                            catching up or aims to excel further, our
                            experienced tutors provide personalized support to
                            meet their unique needs. Join us to build a strong
                            foundation in ELAR that will set your child up for
                            success in school and beyond.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade1.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade2.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           6th-8th Grade ELAR Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Empower Your Middle Schooler with Advanced Reading and Writing Skills!
                            </strong>{" "}
                            <br />
                            <br />
                            Our comprehensive ELAR tutoring program for 6th-8th graders at i12 Excellence Academy is designed to help your child master critical reading, writing, and analytical skills. We focus on enhancing their ability to understand complex texts, develop strong writing techniques, and prepare for high school-level coursework. With personalized instruction, your student will gain the confidence and skills needed to excel in ELAR.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           9th-12th Grade ELAR Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Excel in High School English with Expert Guidance!
                            </strong>{" "}
                            <br />
                            <br />
                            Prepare for college and beyond with our specialized ELAR tutoring for high school students at i12 Excellence Academy. Our program focuses on advanced reading comprehension, literary analysis, essay writing, and critical thinking skills. Whether tackling challenging coursework, preparing for exams, or refining writing techniques, our experienced tutors are here to help your student achieve their academic goals and succeed in English Language Arts.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade3.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade4.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           K-5th Grade Math Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Build a Strong Math Foundation for Your Child's Future!
                            </strong>{" "}
                            <br />
                            <br />
                            Our K-5 math tutoring program at i12 Excellence Academy is designed to make math fun and accessible for young learners. We focus on foundational skills such as number sense, basic operations, problem-solving, and early geometry. With personalized attention, your child will develop the confidence and skills needed to excel in math, setting the stage for future success in school.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           6th-8th Grade Math Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Strengthen Math Skills and Boost Confidence in Middle School!
                            </strong>{" "}
                            <br />
                            <br />
                            Our 6th-8th grade math tutoring program at i12 Excellence Academy is tailored to help students master key concepts in pre-algebra, algebra, geometry, and more. We focus on building problem-solving abilities, improving computational skills, and preparing students for high school math courses. With individualized support, your child will gain the confidence and knowledge needed to excel in math during these critical middle school years.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade5.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade6.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           9th-12th Grade Math Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Achieve Mastery in High School Math with Expert Support!
                            </strong>{" "}
                            <br />
                            <br />
                            Our 9th-12th grade math tutoring program at i12 Excellence Academy is designed to help students excel in subjects like algebra, geometry, trigonometry, pre-calculus, and calculus. Whether your student needs help with challenging coursework, preparing for exams, or honing advanced problem-solving skills, our experienced tutors provide personalized instruction to ensure success. Equip your high schooler with the tools they need to excel in math and prepare for college-level courses.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           4th-12th Grade Science Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Ignite a Passion for Science and Unlock Academic Success!
                            </strong>{" "}
                            <br />
                            <br />
                            Our 4th-12th grade science tutoring program at i12 Excellence Academy is designed to inspire curiosity and deepen understanding in subjects ranging from basic elementary science to advanced high school courses like biology, chemistry, and physics. Whether your student needs help grasping foundational concepts or tackling complex theories, our expert tutors provide personalized support to ensure success in science. Prepare your child for academic excellence and a lifelong love of learning.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade7.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade8.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           7th-12th Grade History Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Explore the Past, Empower the Future with Expert History Tutoring!
                            </strong>{" "}
                            <br />
                            <br />
                            Our 7th-12th grade history tutoring program at i12 Excellence Academy is tailored to help students excel in a range of historical topics, including Texas History for 7th graders. Whether your student is studying ancient civilizations, world history, U.S. history, or government, our experienced tutors provide personalized instruction to ensure academic success. With a focus on critical thinking and analytical skills, we equip your child with the knowledge needed to excel in history and prepare for future academic challenges.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           STAAR Test Prep Tutoring for 3rd-8th Grade at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Ace the STAAR with Targeted Test Prep and Expert Guidance!
                            </strong>{" "}
                            <br />
                            <br />
                            Our STAAR test prep tutoring program at i12 Excellence Academy is designed to help 3rd-8th grade students excel in their State of Texas Assessments of Academic Readiness (STAAR) exams. With a focus on key subjects like math, reading, writing, and science, our experienced tutors provide personalized instruction and proven strategies to boost confidence and improve test scores. Equip your child with the tools they need to succeed on the STAAR and achieve academic excellence.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade9.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade10.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           End of Course (EOC) Test Prep Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Prepare for Success with Expert End of Course Test Prep!
                            </strong>{" "}
                            <br />
                            <br />
                            Our EOC test prep program at i12 Excellence Academy is specifically designed to help 9th-12th grade students excel in their End of Course exams. Whether it's for Algebra, Biology, English, or U.S. History, our experienced tutors offer personalized instruction and targeted strategies to ensure your student is fully prepared. Enhance your child’s test-taking skills, build confidence, and achieve the results needed for academic success and graduation.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           SAT/ACT Test Prep Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Achieve Your Best Score with Expert SAT/ACT Preparation!
                            </strong>{" "}
                            <br />
                            <br />
                            Our SAT/ACT test prep tutoring program at i12 Excellence Academy is designed to help high school students maximize their test scores and enhance their college application profiles. With a focus on critical reading, math, writing, and test-taking strategies, our experienced tutors provide personalized support tailored to each student’s needs. Build confidence, improve your skills, and tackle the SAT/ACT with a strategic approach that sets you up for success.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade11.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                </Row>
              </div>
            </Card>

            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row gutter={[32, 32]} align={"middle"}>
                  <Col lg={10}>
                    <Image
                      src={ImageUrl("grade12.jpg")}
                      preview={false}
                      //   style={{
                      //     width: "100%",
                      //     height: "500px",
                      //   }}
                    />
                  </Col>
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {
                          <>
                           General Test Prep Tutoring at i12 Excellence Academy
                          </>
                        }
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            <strong>
                            Prepare for Any Test with Tailored Guidance and Expert Support!
                            </strong>{" "}
                            <br />
                            <br />
                            Our general test prep tutoring program at i12 Excellence Academy is designed to help students excel in a variety of standardized and academic tests. From entrance exams and state assessments to classroom tests, our experienced tutors provide personalized instruction and effective strategies to boost confidence and improve performance. Equip yourself with the skills needed to tackle any test with confidence and achieve your academic goals.
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default AboutUs;
