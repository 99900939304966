import React,{useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Button, Row, Avatar, Typography, Layout, Card,Form,Input,Radio,DatePicker } from "antd";
import { useNavigate } from "react-router";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { UPLOADS_URL } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { AUTH, COACH } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import {CONTENT_TYPE} from "../../config/constants/index"
import swal from "sweetalert";
import dayjs from 'dayjs';


//icons
import {
  FaLongArrowAltLeft
} from "react-icons/fa";
import {TbCameraPlus} from "react-icons/tb"

function Education() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const [editMode,setEditMode] = useState(false);
  const [imageNew,setImageNew] = useState()

  React.useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  console.log("imageNew",imageNew)

  const onFinish = (values) => {
    setLoading(true);

    Post(COACH.updateProfile,values,token,null)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          console.log(response?.data)
          dispatch(
            addUser({ user: response.data.data, token: token })
          );

          swal("Success!", "Education Updated Successfully", "success");
          setLoading(false);
          setEditMode(false);
          setImageNew()
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {

        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Education</>}
        </Typography.Title>

        <FaLongArrowAltLeft style={{fontSize:"30px" , color:"#333d47", position:"absolute", left:150,cursor:"pointer", marginTop:20}} onClick={()=> navigate(-1)}/>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={18}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >

             
              <Row>
                <Col xs={24} md={24}>
                
                  {editMode ? <>
                    <Form.List
                  name="education"
                  initialValue={user.education?.map(item => {return({...item,start:dayjs(item.start),end:dayjs(item.end),subject:item.subject.join(",")})})}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }, index) => (
                        <>
                          {" "}
                          <Row
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: 20,
                            }}
                          >
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "18px",
                                textDecoration: "underline",
                                color: "#3C3C3B",
                                textAlign: "left",
                                marginTop: 0,
                              }}
                            >
                              Education {index + 1}
                            </Typography.Text>{" "}
                            &emsp;
                            {index > 0 && (
                              <CloseCircleOutlined
                                onClick={() => remove(name)}
                                style={{ color: "#B00000", fontSize: "20px" }}
                              />
                            )}
                          </Row>
                          <Row key={key} gutter={20}>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Institution Name*"
                                name={[name, "school"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter institution name ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter institution name"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Start Date*"
                                name={[name, "start"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter start date ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  size="large"
                                  picker="month"
                                  placeholder="enter start date"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="End Date*"
                                name={[name, "end"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter end date ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  size="large"
                                  picker="month"
                                  placeholder="enter end date"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Subject Studied*"
                                name={[name, "subject"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter coma seperated subjects",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter subject Studied (coma seperated)"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Diploma Earned*"
                                name={[name, "isDiploma"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select one ",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value={true}>
                                    <span
                                      className="fontFamily1"
                                      style={{ color: "grey" }}
                                    >
                                      Yes
                                    </span>
                                  </Radio>
                                  <Radio value={false}>
                                    <span
                                      className="fontFamily1"
                                      style={{ color: "grey" }}
                                    >
                                      No
                                    </span>
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                          {index + 1 < fields?.length && (
                            <hr
                              style={{
                                marginBottom: 20,
                                borderTop: "1px solid #dadada",
                              }}
                            />
                          )}
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          size="large"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Educational Details
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                </> :<>

                {user?.education?.map((item,index) => {
                  console.log("item",item)
                  return(
                  <>
                  <Row>
                  <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "18px",
                                textDecoration: "underline",
                                color: "#3C3C3B",
                                textAlign: "left",
                                marginTop: 0,
                                marginBottom: 20,
                              }}
                            >
                              Education {index + 1}
                            </Typography.Text>
                  </Row>

<Row>
                    <Col xs={12} sm={6}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        School Name
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {item?.school}
                      </Typography.Text>
                    </Col>
 
                    <Col xs={12} sm={6}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        Start Date
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                       {dayjs(item.start).format("MMMM - YYYY")}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col xs={12} sm={6}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        End Date
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                       {dayjs(item.end).format("MMMM - YYYY")}
                      </Typography.Text>
                    </Col>
                    <Col xs={12} sm={6}>
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        Diploma Earned
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {item?.isDiploma ? "Yes" : "No"}
                      </Typography.Text>
                    </Col>
                  </Row>

                  <br/>
                  <Row>
                    <Col xs={12} >
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                      Subject Studied
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "14px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                       {item.subject.join(",")}
                      </Typography.Text>
                    </Col>
                    
                  </Row>

                  {index < user.education?.length - 1 && <><br/>
                  <br/></>}
                  </>
             );
                   

                })}

                  </>}
                 
                  <Row style={{ marginTop: 0 }}>
                   {editMode && <> <Button
                      type="primary"
                      htmlType="submit"
                      className="loginButton"
                     
                    >
                     Save
                    </Button>&emsp;&emsp;
                    <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 30px",
                        cursor: "pointer",
                        color: "black",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                      onClick={(e) => {e.preventDefault(); setEditMode(false)}}
                    >
                     Cancel
                    </Button></> }
                 
                  </Row>

                  <Row style={{ marginTop: 30 }}>
                    &emsp;
                    {/* <Button
                      className="fontFamily1"
                      style={{
                        marginTop: "0px",
                        padding: "10px 40px",
                        cursor: "pointer",
                        color: "black",
                        height: "auto",
                        border: "1px solid #203657",
                        fontWeight: "bold",
                      }}
                      ghost
                      size="large"
                    >
                      Join Lesson
                    </Button> */}
                  </Row>
                </Col>
              </Row>
              </Form>

              {!editMode && <><Button
                      type="primary"
                      htmlType="button"
                      className="loginButton"
                      onClick={() => setEditMode(true)}
                    >
                     Update Education
                    </Button> </>}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Education;
