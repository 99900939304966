import React, { useEffect, useState } from "react";
import {
  Form,
  DatePicker,
  Input,
  Upload,
  Col,
  Radio,
  Row,
  Select,
  Typography,
  Layout,
  Card,
  Button,
  InputNumber,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Post } from "../../config/api/post";
import { Get } from "../../config/api/get";
import {CONTENT_TYPE,SUBJECTS} from "../../config/constants/index"
import { AUTH, SERVICES } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { BiSolidCloudUpload } from "react-icons/bi";
import swal from "sweetalert";

//icons
import { IoMdPin } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMail, FiLock } from "react-icons/fi";

function Signup() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const { Option } = Select;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [temp,setTemp]= useState();




const handleFileChange = ({ fileList }) => {
  setFileList(fileList);
};

const beforeUploadHandler = (file) => {
  // You can perform additional checks on the file here if needed
  setFileList((prevFileList) => [...prevFileList, file]); // Add the file to the fileList state
  return false; // Returning false prevents the default upload behavior
};



  useEffect(() => {
    getAllServices();
  }, []);

  const getAllServices = () => {
    try {
      Get(SERVICES.getAll, token).then((response) => {
        if (response?.status) {
          setServices(response?.data?.docs);
        } else {
          console.log("response", response);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const onFinish = (data) => {
    setLoading(true);
    let values = {...data}

    console.log(values)
    
    const formObject = new FormData();
    
    for (const key in values) {
      if (key !== "resume" && key !== "documents" ) {
        const item = values[key];
        formObject.append(key, item);
      }
    }
    
    values.resume.fileList.forEach((fileItem) => {
      formObject.append("resume", fileItem.originFileObj);
    });
    values.documents.fileList.forEach((fileItem) => {
      formObject.append("documents", fileItem.originFileObj);
    });
    
    Post(AUTH.signup,formObject,null,null,CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        setLoading(false);

        console.log("respose",response)

        if(response?.response?.data?.status == false){
          swal("Oops!", response?.response?.data?.message, "error");
        }
        if (response?.data?.status) {
          swal("System Message!", "Your account request has beet sent to the admin for the approval. You will receive an email after the approval.", "success");
          navigate("/");
        } else {
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });

    return
    // Append education data
    let _education = [...values.education]?.map((item, index) => ({
      isDiploma: item.isDiploma,
      school: item.school,
      subject: item.subject.split(",")?.map((value) => value.trim()),
      start: new Date(item.start),
      end: new Date(item.end),
    }));
    
    formObject.append("education", JSON.stringify(_education));
    
    // Append employment data
    let _employment = [...values.employment]?.map((item, index) => ({
      company: item.company,
      address: item.address,
      position: item.position,
      salary: item.salary,
      reason: item.reason,
      start: new Date(item.start),
      end: new Date(item.end),
    }));
    
    formObject.append("employment", JSON.stringify(_employment));

    let _reference = JSON.stringify([...values.reference])



    formObject.append("reference",_reference);

    formObject.append("image",values.image.fileList[0].originFileObj);

    formObject.append("subjects", JSON.stringify(values.subjects));

    formObject.append("service", JSON.stringify(values.service));

  
    
    // Append other items in the values object
    for (const key in values) {
      if (key !== "education" && key !== "employment" && key !== "image" && key !== "reference" && key !== "subjects"  && key !== "service"   ) {
        const item = values[key];
        formObject.append(key, item);
      }
    }
   

  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Register</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={20}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Typography.Title
                className="fontFamily1"
                style={{
                  fontSize: "25px",
                  fontWeight: 600,
                  color: "#3C3C3B",
                  textAlign: "left",
                  marginTop: 0,
                }}
              >
                Personal Information
              </Typography.Title>

              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="First Name*"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter FullName"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Last Name*"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter LastName"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Email*"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Phone Number*"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input phone number",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Phone Number"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Password*"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Confirm Password*"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Confirm Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Credentials, Board Certificate*"
                      name="boardCertificate"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Approve To Background Check*"
                      name="backgroundCheck"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Ready For Interview*"
                      name="readyForInterview"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Ready To Make Trail Session*"
                      name="readyForTrail"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Are you a state certified teacher in your state?"
                      name="stateCertified"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Are you a TIA?"
                      name="tia"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Applied Position"
                      name="appliedPosition"
                      rules={[
                        {
                          required: true,
                          message: "Please input applied position",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Enter applied position"
                        className="signupSelectBox"
                      >
                        {['Tutor', 'Consultant']?.map(val => <Option key={val} value={val}>{val}</Option>)}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item  
                      // {...restField}
                      label="Earliest Possible Start Date"
                      // name={[name, "start"]}
                      name={"startDate"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter start date ",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        size="large"
                        // picker="month"
                        placeholder="enter start date"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      // {...restField}
                      label="Preferred Interview Date"
                      // name={[name, "start"]}
                      name={"endDate"}
                      rules={[
                        {
                          required: true,
                          message: "Please enter interview date ",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        size="large"
                        // picker="month"
                        placeholder="enter interview date"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                
                <Row gutter={20}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Cover Letter*"
                      name="coverLetter"
                      rules={[
                        {
                          required: true,
                          message: "Please input your bio",
                        },
                      ]}
                    >
                      <Input.TextArea
                        size="large"
                        placeholder="Enter Cover Letter"
                        className="signupFormInput ContactFormInput2"
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="State*"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Please input state",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter State"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={
                        <>
                          Zip Code <span className="redStar">*</span>
                        </>
                      }
                      name="zip"
                      rules={[
                        {
                          required: true,
                          message: "Please input zip code",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Zip Code"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Upload Resume"
                      name="resume"
                      rules={[
                        {
                          required: true,
                          message: "Please upload resume",
                        },
                      ]}
                    >
                      <Upload
                        name="resume"
                        listType="picture"
                        beforeUpload={(file) => {                         
                          return false;
                      }}
                      >
                        <BiSolidCloudUpload
                          style={{ fontSize: "50px", color: "#00a79d" }}
                        />
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Upload Degree"
                      name="documents"
                      rules={[
                        {
                          required: true,
                          message: "Please select Degree",
                        },
                      ]}
                    >
                      <Upload
                        name="documents"
                        listType="picture"
                        beforeUpload={(file) => {                         
                          return false;
                      }}
                      >
                        <BiSolidCloudUpload
                          style={{ fontSize: "50px", color: "#00a79d" }}
                        />
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Home Number*"
                      name="homeNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter home number",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter home number"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Image"
                      name="image"
                      rules={[
                        {
                          required: true,
                          message: "Please select profile image",
                        },
                      ]}
                    >
                      <Upload
                        name="image"
                        listType="picture"
                        beforeUpload={(file) => {                         
                          return false;
                      }}
                      >
                        <BiSolidCloudUpload
                          style={{ fontSize: "50px", color: "#00a79d" }}
                        />
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Social Security Number**"
                      name="socialSecurity"
                      rules={[
                        {
                          required: true,
                          message: "Please input social security number",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter social security number"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Alternate Phone Number*"
                      name="alternatePhone"
                      rules={[
                        {
                          required: true,
                          message: "Please enter alternate phone numner",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter alternate phone numner"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row> */}

                {/* <br />
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#3C3C3B",
                    textAlign: "left",
                    marginTop: 0,
                    marginBottom: 30,
                  }}
                >
                  Application Information
                </Typography.Title>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Have you ever been a Trainer or Consultant?*"
                      name="isTutor"
                      rules={[
                        {
                          required: true,
                          message: "Please select one",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            Yes
                          </span>
                        </Radio>
                        <Radio value={false}>
                          <span
                            className="fontFamily1"
                            style={{ color: "grey" }}
                          >
                            No
                          </span>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Application Type*"
                      name="applicationType"
                      rules={[
                        {
                          required: true,
                          message: "Please select application type",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        className="signupSelectBox"
                        placeholder="Select Application Type"
                      >
                        <option value="TUTORING">Trainer</option>
                        <option value="COACHING">Consultant</option>
                        <option value="BOTH">Both</option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Subjects*"
                      name="subjects"
                      rules={[
                        {
                          required: true,
                          message: "Please select subjects",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        mode="multiple"
                        maxTagCount="responsive"
                        className="signupSelectBox"
                        placeholder="Select Subjects"
                        showSearch
                      >{SUBJECTS?.map((item,index) =>{
                        return(<option value={item}>{item[0].toUpperCase()+item.slice(1)}</option>);
                      })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <br />
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#3C3C3B",
                    textAlign: "left",
                    marginTop: 0,
                    marginBottom: 30,
                  }}
                >
                  Education
                </Typography.Title>

                <Form.List
                  name="education"
                  initialValue={[
                    {
                      school: "",
                      start: null,
                      end: null,
                      subject: "",
                      isDiploma: false,
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }, index) => (
                        <>
                          {" "}
                          <Row
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: 20,
                            }}
                          >
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "18px",
                                textDecoration: "underline",
                                color: "#3C3C3B",
                                textAlign: "left",
                                marginTop: 0,
                              }}
                            >
                              Education {index + 1}
                            </Typography.Text>{" "}
                            &emsp;
                            {index > 0 && (
                              <CloseCircleOutlined
                                onClick={() => remove(name)}
                                style={{ color: "#B00000", fontSize: "20px" }}
                              />
                            )}
                          </Row>
                          <Row key={key} gutter={20}>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Institution Name*"
                                name={[name, "school"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter institution name ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter institution name"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Start Date*"
                                name={[name, "start"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter start date ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  size="large"
                                  picker="month"
                                  placeholder="enter start date"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="End Date*"
                                name={[name, "end"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter end date ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  size="large"
                                  picker="month"
                                  placeholder="enter end date"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Subject Studied*"
                                name={[name, "subject"]}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter coma seperated subjects",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter subject Studied (coma seperated)"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Diploma Earned*"
                                name={[name, "isDiploma"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select one ",
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value={true}>
                                    <span
                                      className="fontFamily1"
                                      style={{ color: "grey" }}
                                    >
                                      Yes
                                    </span>
                                  </Radio>
                                  <Radio value={false}>
                                    <span
                                      className="fontFamily1"
                                      style={{ color: "grey" }}
                                    >
                                      No
                                    </span>
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                          {index + 1 < fields?.length && (
                            <hr
                              style={{
                                marginBottom: 20,
                                borderTop: "1px solid #dadada",
                              }}
                            />
                          )}
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          size="large"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Educational Details
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <br />
                <br />
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#3C3C3B",
                    textAlign: "left",
                    marginTop: 0,
                    marginBottom: 30,
                  }}
                >
                  Bio
                </Typography.Title>
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Tag Line*"
                      name="tagLine"
                      rules={[
                        {
                          required: true,
                          message: "Please enter tag line",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter TagLine"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Detailed Bio*"
                      name="bio"
                      rules={[
                        {
                          required: true,
                          message: "Please input your bio",
                        },
                      ]}
                    >
                      <Input.TextArea
                        size="large"
                        placeholder="Enter Detailed Bio"
                        className="signupFormInput ContactFormInput2"
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br />
                <br />
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#3C3C3B",
                    textAlign: "left",
                    marginTop: 0,
                    marginBottom: 30,
                  }}
                >
                  Former Employment
                </Typography.Title>

                <Form.List
                  name="employment"
                  initialValue={[
                    {
                      company: "",
                      address: "",
                      salary: "",
                      position: "",
                      start: null,
                      end: null,
                      reason: "",
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }, index) => (
                        <>
                          <Row
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: 20,
                            }}
                          >
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "18px",
                                textDecoration: "underline",
                                color: "#3C3C3B",
                                textAlign: "left",
                                marginTop: 0,
                              }}
                            >
                              Employment {index + 1}
                            </Typography.Text>{" "}
                            &emsp;
                            {index > 0 && (
                              <CloseCircleOutlined
                                onClick={() => remove(name)}
                                style={{ color: "#B00000", fontSize: "20px" }}
                              />
                            )}
                          </Row>
                          <Row key={key} gutter={20}>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Company Name*"
                                name={[name, "company"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter company name ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter company name"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Company Address*"
                                name={[name, "address"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter company address ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter company address"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Salary*"
                                name={[name, "salary"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter salary ",
                                  },
                                ]}
                              >
                                <InputNumber
                                  style={{ width: "100%" }}
                                  size="large"
                                  formatter={(value) =>
                                    `$ ${value}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )
                                  }
                                  placeholder="Enter salary"
                                  className="signupFormInput signupSelectBox"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Position*"
                                name={[name, "position"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter position ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter position"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Start Date*"
                                name={[name, "start"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter start date ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  size="large"
                                  picker="month"
                                  placeholder="enter start date"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="End Date*"
                                name={[name, "end"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter end date ",
                                  },
                                ]}
                              >
                                <DatePicker
                                  style={{ width: "100%" }}
                                  size="large"
                                  picker="month"
                                  placeholder="enter end date"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24}>
                              <Form.Item
                                {...restField}
                                label="Reason for Leaving*"
                                name={[name, "reason"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter leaving reason ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter reason for leaving"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {index + 1 < fields?.length && (
                            <hr
                              style={{
                                marginBottom: 20,
                                borderTop: "1px solid #dadada",
                              }}
                            />
                          )}
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          size="large"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Employment Details
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <br />
                <br />
                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#3C3C3B",
                    textAlign: "left",
                    marginTop: 0,
                    marginBottom: 30,
                  }}
                >
                  Professional References
                </Typography.Title>

                <Form.List
                  name="reference"
                  initialValue={[
                    {
                      name: "",
                      phone: "",
                      email: "",
                      company: "",
                      year: "",
                    },
                  ]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }, index) => (
                        <>
                          <Row
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: 20,
                            }}
                          >
                            <Typography.Text
                              className="fontFamily1"
                              style={{
                                fontSize: "18px",
                                textDecoration: "underline",
                                color: "#3C3C3B",
                                textAlign: "left",
                                marginTop: 0,
                              }}
                            >
                              Reference {index + 1}
                            </Typography.Text>{" "}
                            &emsp;
                            {index > 0 && (
                              <CloseCircleOutlined
                                onClick={() => remove(name)}
                                style={{ color: "#B00000", fontSize: "20px" }}
                              />
                            )}
                          </Row>
                          <Row key={key} gutter={20}>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Name*"
                                name={[name, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Reference name ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter Reference name"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Phone*"
                                name={[name, "phone"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter reference phone ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter reference phone"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Email*"
                                name={[name, "email"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter reference email ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter reference email"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Company*"
                                name={[name, "company"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter reference company ",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Enter reference company"
                                  className="signupFormInput"
                                />
                              </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                              <Form.Item
                                {...restField}
                                label="Year Acquainted*"
                                name={[name, "year"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter year acquainted ",
                                  },
                                ]}
                              >
                                <InputNumber
                                  style={{ width: "100%" }}
                                  size="large"
                                  placeholder="Enter year acquainted"
                                  className="signupFormInput signupSelectBox"
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          {index + 1 < fields?.length && (
                            <hr
                              style={{
                                marginBottom: 20,
                                borderTop: "1px solid #dadada",
                              }}
                            />
                          )}
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          size="large"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Reference
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <br />

                <Typography.Title
                  className="fontFamily1"
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "left",
                    marginTop: 0,
                  }}
                >
                  Login Credentials
                </Typography.Title>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Password*"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Confirm Password*"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Confirm Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <br /> */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                  >
                    {loading ? "Loading..." : "Register"}
                  </Button>
                </Form.Item>
              </Form>

              <Typography.Text
                className="fontFamily1"
                style={{
                  fontSize: "14px",
                  color: "#3C3C3B",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 30,
                }}
              >
                <>
                  Already have an account{" "}
                  <span
                    onClick={() => navigate("/signin")}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Login Now
                  </span>
                </>
              </Typography.Text>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default Signup;
