import React from "react";

import { Col, Row, Typography, Layout, Card } from "antd";

function AboutUs() {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> About Us</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={20}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
                margin: "20px 0",
              }}
            >
              <div className="left-right-box">
                <Row
                  gutter={[32, 32]}
                  align={"middle"}
                  justify={"space-between"}
                >
                  <Col lg={14}>
                    <div className="left-text-box">
                      <Typography.Title
                        className="fontFamily1"
                        style={{
                          fontSize: "35px",
                          fontWeight: 600,
                          color: "black",
                          textAlign: "left",
                          marginTop: 0,
                        }}
                      >
                        {<>We Offer online learning.</>}
                      </Typography.Title>
                      <Typography.Text
                        className="fontFamily1"
                        style={{
                          fontSize: "16px",
                          color: "grey",
                          textAlign: "left",
                        }}
                      >
                        {
                          <>
                            At i12 Excellence Academy, we provide personalized
                            support through both on-site small group tutoring
                            sessions and flexible online tutoring options.
                            Whether you prefer learning in a collaborative
                            setting or from the comfort of home, we have the
                            right solution to meet your academic needs and help
                            you succeed!
                            <br />
                            <br />
                            At i12 Excellence Academy, we go above and beyond to
                            connect your student with the perfect tutor. By
                            considering everything you share with us, along with
                            your student’s assessment results and over 100
                            additional attributes, we pinpoint the ideal match.
                            We’re committed to quality—every tutor is rigorously
                            vetted through tutoring simulations, background
                            checks, and interviews, with fewer than 15% of
                            applicants making the cut. Only the best tutors are
                            selected to support your student’s success!
                            <br />
                            <br />
                            Unlimited Access to Comprehensive Learning Resources
                            At i12 Excellence Academy, our family-wide resources
                            offer unlimited access to live classes, instant
                            1-on-1 tutoring, and prompt homework help through
                            Tutor Chat. Additionally, we provide self-study
                            tools with standards-based practice problems,
                            instructional videos, and adaptive diagnostics to
                            ensure personalized learning and growth. With these
                            rich learning resources, every family member
                            receives the support they need to achieve academic
                            success!
                          </>
                        }
                      </Typography.Text>
                    </div>
                  </Col>
                  <Col lg={10}>
                    <Card
                      className="aboutCard1"
                      bordered={false}
                      style={{
                        width: "100%",
                        height: "650px",
                      }}
                    ></Card>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default AboutUs;
